$hero-mobile: "~@/assets/images/homepage/hero-mobile.webp";
$hero-tablet: "~@/assets/images/homepage/hero-tablet.webp";
$hero-desktop: "~@/assets/images/homepage/hero-desktop.webp";

#hero-section {
  background-image: url($hero-mobile);

  @media (min-width: 480px) {
    background-image: url($hero-tablet);
  }

  @media (min-width: 1024px) {
    background-image: url($hero-desktop);
  }
}
