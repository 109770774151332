@tailwind base;
@tailwind components;
@tailwind utilities;

// Ce fichier permet de gérer les styles globaux

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  background-color: #1c1c1c;
  font-size: 16px;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
}

header {
  max-width: 100vw !important;
  height: 64px !important;
}

.custom-nav-bar {
  height: calc(100dvh - 64px) !important;
}
